const buttons = document.getElementsByClassName("tab-button");
const containers = document.getElementsByClassName("tab-content");
for (let index = 0; index < buttons.length; index++) {
    const element = buttons[index];
    element.addEventListener("click", () => {
        const activeItems = document.getElementsByClassName("active");
        const ID = element.getAttribute('data-id');
        console.log(activeItems);
        for (let i = 0; i < activeItems.length; i++) {
            const activeItem = activeItems[i];
            const aID = activeItem.getAttribute('data-id');
            console.log(activeItem);
            if(aID == ID){
                activeItem.classList.remove("active");
            }
        };
        element.classList.add("active");
        let dataBtnAnchor = element.getAttribute('data-button-anchor');
        for (let y = 0; y < containers.length; y++) {
            const container = containers[y];
            const cID = container.getAttribute('data-id');
            if(container.className == "tab-content active" && cID == ID){
                container.classList.remove("active")
            }
            console.log(container.className)
            let dataTabAnchor = container.getAttribute('data-tab-anchor');
            if (dataTabAnchor === dataBtnAnchor && cID == ID) {
                container.classList.add("active");
            }
        }
    });
};