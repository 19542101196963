window.addEventListener("DOMContentLoaded", (event) => {
    var submenuItems = document.getElementsByClassName("link-list-item");
    var submenus = document.getElementsByClassName("additionalmenu");
    
    for (let index = 0; index < submenuItems.length; index++) {
        const submenuItem = submenuItems[index];
        if(submenuItem.classList.contains("active")){
            submenuItem.addEventListener("click", ()=>{
                for (let i = 0; i < submenus.length; i++) {
                    const submenu = submenus[i];
                    submenu.classList.toggle("mobileOpen")
                }
            })
        }
        
    }
    window.addEventListener('scroll', function() {
        for (let i = 0; i < submenus.length; i++) {
            const submenu = submenus[i];
            submenu.classList.remove("mobileOpen")
        }
    })
});

