window.addEventListener("DOMContentLoaded", (event) => {
    var generatedHiddenFieldList = document.getElementsByClassName("generatedHiddenField");
    var i;
    for (i = 0; i < generatedHiddenFieldList.length; i++) {
        let generatedHiddenFieldDiv = generatedHiddenFieldList[i];
        let generatedHiddenField = generatedHiddenFieldDiv.querySelector('input');

        var now = new Date(),
            dd = String(now.getDate()).padStart(2, '0'),
            mm = String(now.getMonth() + 1).padStart(2, '0'),
            yyyy = now.getFullYear();

        switch(generatedHiddenFieldDiv.dataset.generationtype){
            case 'currentLanguage':
                generatedHiddenField.value=document.documentElement.lang;
                break;
            case 'currentDate':
                generatedHiddenField.value=(mm + '/' + dd + '/' + yyyy);
                break;
            case 'currentDateTime':
                generatedHiddenField.value=(mm + '/' + dd + '/' + yyyy+" "+now.toLocaleTimeString());
                break;
            case 'clientUserAgent':
                generatedHiddenField.value=(navigator.userAgent);
                break;
        }
    }


    const forms = document.getElementsByTagName('form');
    for (i = 0; i < forms.length; i++) {

        let formID = forms[i].id;

        forms[i].addEventListener("submit", () => {
            sendGAEvent('submit', formID, 'n/a', 'n/a');
        });
    }
});