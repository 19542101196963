// Utils
var ww = window.innerWidth;

// CTA menu
var ctaMenuDesktop = document.querySelector('.cta-menu-desktop');

if (ctaMenuDesktop !== null) {
    // remove desktop cta if mobile
    if (ww <= 1279) {
        ctaMenuDesktop.remove();
    }

    var ctaMenuBtn = document.querySelector('.cta-menu-btn');
    var ctaMenuDropdown = document.querySelector('.cta-menu-dropdown');
    var ctaMenuDropdownHeight = document.querySelector('.cta-menu-dropdown ul').offsetHeight;

    var toggleCtaDropdown = function () {
        if (ctaMenuDropdown.classList.contains('open')) {
            ctaMenuDropdown.classList.remove('open');
            ctaMenuBtn.classList.remove('open');
            ctaMenuDropdown.style.height = 0;
        } else {
            ctaMenuDropdown.classList.add('open');
            ctaMenuBtn.classList.add('open')
            ctaMenuDropdown.style.height = (ctaMenuDropdownHeight + 20) + 'px';
        }
    };

    window.addEventListener('click', function (e) {
        if (e.target === ctaMenuBtn || e.target === ctaMenuBtn.querySelector('span')) {
            toggleCtaDropdown();
        } else if (e.target !== ctaMenuBtn && ctaMenuDropdown.classList.contains('open')) {
            // close drodown if click outside
            toggleCtaDropdown();
        }
    });
}

var ctaMenu2Desktop = document.querySelector('.cta-menu2-desktop');

if (ctaMenu2Desktop !== null) {
    // remove desktop cta if mobile
    if (ww <= 1279) {
        ctaMenu2Desktop.remove();
    }

    var ctaMenu2Btn = document.querySelector('.cta-menu2-btn');
    var ctaMenu2Dropdown = document.querySelector('.cta-menu2-dropdown');
    var ctaMenu2DropdownHeight = document.querySelector('.cta-menu2-dropdown ul').offsetHeight;

    var toggleCta2Dropdown = function () {
        if (ctaMenu2Dropdown.classList.contains('open')) {
            ctaMenu2Dropdown.classList.remove('open');
            ctaMenu2Btn.classList.remove('open');
            ctaMenu2Dropdown.style.height = 0;
        } else {
            ctaMenu2Dropdown.classList.add('open');
            ctaMenu2Btn.classList.add('open')
            ctaMenu2Dropdown.style.height = (ctaMenu2DropdownHeight + 20) + 'px';
        }
    };

    window.addEventListener('click', function (e) {
        if (e.target === ctaMenu2Btn || e.target === ctaMenu2Btn.querySelector('span')) {
            toggleCta2Dropdown();
        } else if (e.target !== ctaMenu2Btn && ctaMenu2Dropdown.classList.contains('open')) {
            // close drodown if click outside
            toggleCta2Dropdown();
        }
    });
}



// function to make the menu sticky on certain scroll position
var isSticky = false;
var mainHeader = document.getElementById('main-header');


window.addEventListener('scroll', function () {
    mainHeader = document.getElementById('main-header');
    if (window.scrollY >= 136 && !isSticky) {
        isSticky = true;
        mainHeader.classList.add("sticky");
        document.body.classList.add("sticky");

    } else if (window.scrollY < 136 && isSticky) {
        isSticky = false;
        mainHeader.classList.remove('sticky');
        document.body.classList.remove("sticky");
    }
});



// desktop menu
var firstLvlMenuButtons = document.querySelectorAll('#navigation button[aria-expanded][aria-controls]');
for (let i = 0; i < firstLvlMenuButtons.length; i++) {
    firstLvlMenuButtons[i].addEventListener('click', (e) => {
        let clickedItemParent = e.target.closest('li');
        if (clickedItemParent.classList.contains('submenu-open')) {
            clickedItemParent.classList.toggle('submenu-open');
        } else {
            firstLvlMenuButtons.forEach(function (e) {
                e.closest('li').classList.remove('submenu-open');
            });
            clickedItemParent.classList.toggle('submenu-open');
        }
    });
}

// find second level active link and apply active class to parent button
var secondLvlActiveLink = document.querySelector('#navigation .second-level-item.active');
if (secondLvlActiveLink !== null) {
    secondLvlActiveLink.closest('.has-submenu').classList.add('active');
}

// close dropdown menu when clicking outside
document.addEventListener('click', (e) => {
    if (!e.target.closest('#navigation ul')) {
        firstLvlMenuButtons.forEach(function (e) {
            e.closest('li').classList.remove('submenu-open');
        });
    }
});


// remove submenu-open class on focus change to prevent submenu not opening when coming back to the tab
document.addEventListener("visibilitychange", () => {
    firstLvlMenuButtons.forEach(function (e) {
        e.closest('li').classList.remove('submenu-open');
    });
});

// Mobile Menu
// Copy Desktop Menu Content for Mobile Menu
var mobileMenu = document.getElementById("mobile-navigation");
var desktopMenu = document.getElementById("navigation");

if(mobileMenu && desktopMenu){
    mobileMenu.innerHTML = desktopMenu.innerHTML;
}


// remove accessible-nav class from mobile menu
var accessibleNav = document.querySelector('#mobile-navigation ul.accessible-nav');
if(accessibleNav){
    accessibleNav.classList.remove("accessible-nav");
}


// Open & Close Mobile Menu
var hambMenu = document.getElementById("mobile-menu-open");
var menuPanel = document.getElementById("mobile-panel");
var menuClose = document.getElementById("mobile-menu-close");

if(hambMenu){
    hambMenu.addEventListener("click", function () {
        document.body.classList.add("menu-open");
        menuPanel.classList.add("open");
    })
}

if(menuClose){
    menuClose.addEventListener("click", function () {
        //close all first
        closeAllMobileMenu ();
        
        document.body.classList.remove("menu-open");
        menuPanel.classList.remove("open");
    })
}



// handle mobile menu drop down
var menuLinks = document.querySelectorAll("#mobile-navigation .has-submenu");

for (i = 0; i < menuLinks.length; i++) {
    let menuLink = menuLinks[i];
    var menuLinkTrigger = menuLinks[i].querySelector('div.mobile');
    menuLinkTrigger.addEventListener("click", () => {
        if (!menuLink.classList.contains("active")) {
            //close all first
            closeAllMobileMenu ();

            //open the one
            menuLink.classList.toggle("active");
            var submenu = menuLink.querySelector('ul');
            submenu.style.maxHeight = submenu.scrollHeight + "px";
        } else {
            menuLink.classList.remove("active");
            var submenu = menuLink.querySelector('ul');
            submenu.style.maxHeight = null;
        }
    });
}

function closeAllMobileMenu () {
    for (let y = 0; y < menuLinks.length; y++) {
        menuLinks[y].classList.remove("active");
        var submenuReset = menuLinks[y].querySelector('ul');
        if (submenuReset.style.maxHeight) {
            submenuReset.style.maxHeight = null;
        }
    }
}

// Accessibility
// handle mainmenu accessibility
var AccessibleNav = function (domNode) {
    this.rootNode = domNode;
    this.triggerNodes = [];
    this.controlledNodes = [];
    this.openIndex = null;
    this.useArrowKeys = true;
};

AccessibleNav.prototype.init = function () {
    var buttons = this.rootNode.querySelectorAll('button[aria-expanded][aria-controls]');
    for (var i = 0; i < buttons.length; i++) {
        var button = buttons[i];
        var menu = button.parentNode.querySelector('ul');
        if (menu) {
            // save ref to button and controlled menu
            this.triggerNodes.push(button);
            this.controlledNodes.push(menu);

            // collapse menus
            button.setAttribute('aria-expanded', 'false');
            this.toggleMenu(menu, false);

            // attach event listeners
            menu.addEventListener('keydown', this.handleMenuKeyDown.bind(this));
            button.addEventListener('click', this.handleButtonClick.bind(this));
            button.addEventListener('keydown', this.handleButtonKeyDown.bind(this));
        }
    }

    this.rootNode.addEventListener('focusout', this.handleBlur.bind(this));
};


AccessibleNav.prototype.toggleMenu = function (domNode, show) {
    if (domNode) {
        domNode.style.display = show ? 'block' : 'none';
    }
};

AccessibleNav.prototype.toggleExpand = function (index, expanded) {
    // close open menu, if applicable
    if (this.openIndex !== index) {
        this.toggleExpand(this.openIndex, false);
    }

    // handle menu at called index
    if (this.triggerNodes[index]) {
        this.openIndex = expanded ? index : null;
        this.triggerNodes[index].setAttribute('aria-expanded', expanded);
        this.toggleMenu(this.controlledNodes[index], expanded);
    }
};

AccessibleNav.prototype.controlFocusByKey = function (keyboardEvent, nodeList, currentIndex) {
    switch (keyboardEvent.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
            keyboardEvent.preventDefault();
            if (currentIndex > -1) {
                var prevIndex = Math.max(0, currentIndex - 1);
                nodeList[prevIndex].focus();
            }
            break;
        case 'ArrowDown':
        case 'ArrowRight':
            keyboardEvent.preventDefault();
            if (currentIndex > -1) {
                var nextIndex = Math.min(nodeList.length - 1, currentIndex + 1);
                nodeList[nextIndex].focus();
            }
            break;
        case 'Home':
            keyboardEvent.preventDefault();
            nodeList[0].focus();
            break;
        case 'End':
            keyboardEvent.preventDefault();
            nodeList[nodeList.length - 1].focus();
            break;
    }
};


/* Event Handlers */
AccessibleNav.prototype.handleBlur = function (event) {
    var menuContainsFocus = this.rootNode.contains(event.relatedTarget);
    if (!menuContainsFocus && this.openIndex !== null) {
        this.toggleExpand(this.openIndex, false);
    }
};

AccessibleNav.prototype.handleButtonKeyDown = function (event) {
    var targetButtonIndex = this.triggerNodes.indexOf(document.activeElement);

    // close on escape
    if (event.key === 'Escape') {
        this.toggleExpand(this.openIndex, false);
    }

    // move focus into the open menu if the current menu is open
    else if (this.useArrowKeys && this.openIndex === targetButtonIndex && event.key === 'ArrowDown') {
        event.preventDefault();
        this.controlledNodes[this.openIndex].querySelector('a').focus();
    }

    // handle arrow key navigation between top-level buttons, if set
    else if (this.useArrowKeys) {
        this.controlFocusByKey(event, this.triggerNodes, targetButtonIndex);
    }
};

AccessibleNav.prototype.handleButtonClick = function (event) {
    var button = event.target;
    var buttonIndex = this.triggerNodes.indexOf(button);
    var buttonExpanded = button.getAttribute('aria-expanded') === 'true';
    this.toggleExpand(buttonIndex, !buttonExpanded);
};

AccessibleNav.prototype.handleMenuKeyDown = function (event) {
    if (this.openIndex === null) {
        return;
    }

    var menuLinks = Array.prototype.slice.call(this.controlledNodes[this.openIndex].querySelectorAll('a'));
    var currentIndex = menuLinks.indexOf(document.activeElement);

    // close on escape
    if (event.key === 'Escape') {
        this.triggerNodes[this.openIndex].focus();
        this.toggleExpand(this.openIndex, false);
    }

    // handle arrow key navigation within menu links, if set
    else if (this.useArrowKeys) {
        this.controlFocusByKey(event, menuLinks, currentIndex);
    }
};


/* Initialize Accessible Nav */
window.addEventListener('load', () => {
    var menus = document.querySelectorAll('.accessible-nav');
    var accessibleMenus = [];

    for (var i = 0; i < menus.length; i++) {
        accessibleMenus[i] = new AccessibleNav(menus[i]);
        accessibleMenus[i].init();
    }
});