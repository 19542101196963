const productDetailExpandBtn = document.querySelectorAll('.product-detail .expand-btn');
const productDetailExpandIcon = document.querySelectorAll('.product-detail .expand-btn-icons');

// on click on expand button get the closest parent with class product-detail and toggle class expanded

for (let i = 0; i < productDetailExpandBtn.length; i++) {
    const btn = productDetailExpandBtn[i];
    btn.addEventListener("click", () => {
        //btn.preventDefault();
        let productDetail = btn.closest('.product-detail');
        // if productDetail has class expanded remove it and add the closed class
        if (productDetail.classList.contains('expanded')) {
            productDetail.classList.remove('expanded');
            productDetail.classList.add('closed');
            productDetail.setAttribute('aria-expanded', 'false');

            btn.setAttribute('aria-expanded', 'false');
            btn.classList.remove('expanded');
            btn.classList.add('closed');
            
            return;
        } else {
            // if productDetail has class closed remove it and add the expanded class
            if (productDetail.classList.contains('closed')) {
                productDetail.classList.remove('closed');
                productDetail.classList.add('expanded');
                productDetail.setAttribute('aria-expanded', 'true');

                btn.setAttribute('aria-expanded', 'true');
                btn.classList.remove('closed');
                btn.classList.add('expanded');
                
                return;
            }
        }
    }, false);
};


