window.addEventListener('DOMContentLoaded', function(){
	var timeInterval = '7000'; // Time between slides
	var carousel = document.querySelector('#slides-items');
	var items = document.querySelectorAll('.slide-item');
	var listeItems = document.getElementById('slides-items');
	var itemsContainer = document.querySelector(".slide-container"); 
	var isEditor = document.querySelector(".cssForEditor"); 
	var leftArrow = document.getElementById("left-arrow")
	var rightArrow = document.getElementById("right-arrow")
	var currentSlide = 0;
	var positionOfSlides = [];
	var positionOfSlidesIndex;
	if (carousel !== undefined && items !== undefined && carousel !== null && items !== null && isEditor == null) {
		var itemscount = items.length;
		var slideCounts = itemscount / 2;
		positionOfSlidesIndex = new Array(slideCounts);
		for (let a = 0; a < positionOfSlidesIndex.length; a++) {
			positionOfSlidesIndex[a] = [];
		}
		var sliderWidth = listeItems.offsetWidth,
			sliderWidthWOpadding = sliderWidth - ( 16 * 2 );
		itemsContainer.style.transform = "translate(-"+ sliderWidth+"px)";
		itemsContainer.style.transition = "0s";
		itemsContainer.style.width = (itemscount * sliderWidth) + "px";
		for (let i = 0; i < items.length; i++) {
			const element = items[i];
			element.style.width = sliderWidthWOpadding + "px";
		}
	
		//Ecouteur pour le tactile
		itemsContainer.addEventListener('touchstart', event => handleTouchStart(event));
		itemsContainer.addEventListener('touchmove', event => handleTouchMove(event));
		itemsContainer.onmousedown = event => handleTouchStart(event);

		//Ecouteur pour les flèches
		leftArrow.addEventListener("click", () => slideprev());
		rightArrow.addEventListener("click", () => slidenext());



		function getTouches(evt) {
			if(evt.type == 'touchstart' || evt.type == 'touchmove'){
				return evt.touches[0].clientX;
			} else {
				return evt.clientX;
			}
		}

		function handleTouchStart(evt) {
			evt.preventDefault();
			
			xDown = getTouches(evt);
			
			listeItems.addEventListener('mousemove', handleTouchMove, true);
		};

		function handleTouchMove(evt) {
			evt.preventDefault();
			if (!xDown) {
				return;
			}
			var xUp = getTouches(evt);
			var xDiff = xDown - xUp;

			if (xDiff > 0) {
				/* left swipe */
				slidenext();
			} else {
				/* right swipe */
				slideprev();
			}

			/* reset values */
			xDown = null;
			yDown = null;
			listeItems.removeEventListener('mousemove', handleTouchMove, true);

		};

		// Create Dots
		var dotbox = document.createElement('div');
		dotbox.classList.add('slides-dots');
		// carousel.after(dotbox); Not supported by Edge => see next line
		carousel.parentNode.insertBefore(dotbox, carousel.nextSibling);			
		for (var i=0; i<itemscount; i++) {
			if(!items[i].className.includes("clone")){
				dotbox.insertAdjacentHTML('beforeend', '<button aria-controls="slide-'+(i+1)+'" aria-label="Slide number '+(i+1)+'" aria-selected="'+(document.querySelector('.slideactive').getAttribute('id').slice(-1) == (i+1) ? 'true' : 'false')+'"></button>');
			}

		}	
		dotbox.style.right = (sliderWidth / 2 - ((18 * slideCounts) / 2)) + "px";
			
		var dots = document.querySelectorAll('.slides-dots button');
		var playpause = null;
		setOrder();

		function slideprev() {
			itemsContainer.style.transition = "0.5s";
			itemsContainer.style.transform = "translate(0px)";

			// changePosition
			let arrayClone = positionOfSlides;
			arrayClone.push(positionOfSlides[0]);
			arrayClone.splice(0, 1);
			positionOfSlides = arrayClone;
			setTimeout( () => {
				itemsContainer.style.transition = "0s";
				itemsContainer.style.transform = "translate(-"+ sliderWidth+"px)";
				changeOrder();
				changeDot();
			}, 500);
		}
		
		function slidenext() {
			itemsContainer.style.transition = "0.5s";
			itemsContainer.style.transform = "translate(-"+ ( sliderWidth * 2 ) +"px)";
			// changePosition
			let arrayClone = positionOfSlides;
			arrayClone.unshift(positionOfSlides[positionOfSlides.length - 1]);
			arrayClone.splice(positionOfSlides.length - 1, 1);
			positionOfSlides = arrayClone;
			setTimeout( () => {
				itemsContainer.style.transition = "0s";
				itemsContainer.style.transform = "translate(-"+ sliderWidth+"px)";
				changeOrder();
				changeDot();
			}, 500);
		}

		function setOrder() {
			//
			for (let i=0; i<itemscount; i++) {
				if(i == itemscount - 1){
					positionOfSlides.push(-1)
				}else{
					positionOfSlides.push(i)
				}
				
			}
			let y = 0;
			while(y<itemscount){
				for (let z = 0; z < positionOfSlidesIndex.length; z++) {
					positionOfSlidesIndex[z].push(positionOfSlides[y]);
					y++
				}
			}
			changeOrder();
		}
		function changeOrder() {
			for (let i=0; i<positionOfSlides.length; i++) {
				items[i].style.order = positionOfSlides[i];
			}
		}		
		function changeDot(){
			for (let p = 0; p < positionOfSlides.length; p++) {
				if(positionOfSlides[p] === 0){
					let slide = p;
					if(slide>(positionOfSlides.length/2)-1){
						slide = slide - ((positionOfSlides.length/2));
						
					}
					
					let oldDot = document.querySelector('.slides-dots button[aria-selected="true"]');
					let newDot = document.querySelector('.slides-dots button[aria-controls="slide-' + (slide + 1) + '"]');

					// Remove current
					oldDot.setAttribute('aria-selected', 'false');
							
					// Add Next
					newDot.setAttribute('aria-selected', 'true');
				}
			}
		}			
		// Dots Navigate
		[].map.call(dots, function(dot) {

			dot.addEventListener('click', function(e) {
				var dotcurrent = document.querySelector('.slides-dots button[aria-selected="true"]');
				var dotclick = dot.getAttribute('aria-controls');
				var targetdot = document.querySelector('button[aria-controls="'+dotclick+'"]');

				var dotclickSplit = dotclick.split("-");
				var targetSlideString = dotclickSplit[1];
				var targetSlideNum = Number(targetSlideString);
				var targetSlideIndex = targetSlideNum - 1;
				var realIndex;
				var currentIndex;
				for (let l = 0; l < positionOfSlides.length; l++) {
					if (positionOfSlides[l] === 0) {
						currentIndex = l;
					}
				}
				if(targetSlideIndex === 0){
					realIndex = 0;
				}else if (targetSlideIndex === 1) {
					realIndex = -1;
				}else{
					realIndex = itemscount - targetSlideIndex 
				}

				var targetOrder = [];
				for (let i=0; i<itemscount; i++) {
					
					if(realIndex == itemscount - 1){
						realIndex = -1;
						targetOrder.push(realIndex)
					}else{
						targetOrder.push(realIndex)
					}
					realIndex++;
				}

				var diff = currentIndex - targetSlideIndex;
				var sliderWidthNeg = sliderWidth * -1;
				var diffPx;
				if(diff < 0){
					if(diff < -1 * slideCounts){
						let newDiff = diff + itemscount;
						diffPx = newDiff * sliderWidth
					}else{
						diffPx = diff * sliderWidth
					}
				}else if(diff === 0 || diff === slideCounts){
					//do nothing
					diffPx = 0;
				}else{
					if (diff> slideCounts) {
						let newDiff = diff - itemscount;
						diffPx = newDiff * sliderWidth
					}else{
						diffPx = diff * sliderWidth
					}
					if(diffPx>sliderWidth){
						diffPx = diffPx - (sliderWidth * slideCounts)
					}
				}
				if(diffPx < sliderWidthNeg * slideCounts){
					diffPx = diffPx + (sliderWidth * slideCounts);
				}

				itemsContainer.style.transition = "0.5s";
				itemsContainer.style.transform = "translate("+ ( sliderWidthNeg + diffPx ) +"px)";

				// Remove current
				dotcurrent.setAttribute('aria-selected', 'false');
									
				// Add Target
				targetdot.setAttribute('aria-selected', 'true');

				positionOfSlides = targetOrder;
				setTimeout( () => {
					itemsContainer.style.transition = "0s";
					itemsContainer.style.transform = "translate(-"+ sliderWidth+"px)";
					changeOrder();
					
				}, 500);
				
		
				e.preventDefault();
			
			},false);
			
		},false);
				
				
		
	} // End if test
});
