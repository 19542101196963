// modal script
var modals = document.querySelectorAll('.modal-backdrop');
var modalIsOpen = false;
var targetLink;
var closeBtn = document.querySelectorAll('.modal-close');
var pageContent = document.querySelector('#page-content-wrapper');

// move all modals to the end of the body
var modalsBackdrop = document.querySelectorAll('.modal-backdrop');
modalsBackdrop.forEach(function(modalsBackdrop){
    document.body.appendChild(modalsBackdrop);
});

// open target modal
document.addEventListener('click', function(e) {
    targetLink = e.target.closest('a[href*="modal://"]');
    if (targetLink != null) {
        e.preventDefault();
        // add class to body for modal trap and trigger backdrop
        document.body.classList.add('modal-open');

        // set aria hidden attribute on page content
        pageContent.setAttribute('aria-hidden', 'true');

        // get the target modal unique name
        var modalName = targetLink.getAttribute('href').split('//')[1];

        // define the target modal
        var targetModal = document.querySelectorAll('[data-modal-name="'+ modalName +'"]')[0];

        var thisModalBtn = targetModal.querySelector('.modal-close');

        // add class to the target modal
        targetModal.classList.add('modal-is-open');
        targetModal.setAttribute('aria-hidden', 'false');
        thisModalBtn.focus();
        modalIsOpen = true;
    }
}, false);


// close modal
var closeModal = function(e) {
    if (modalIsOpen = true) {
        document.body.classList.remove('modal-open');

        // remove aria hidden attribute on page content
        pageContent.setAttribute('aria-hidden', 'false');

        modals.forEach(function(modals){
            modals.classList.remove('modal-is-open');
            modals.setAttribute('aria-hidden', 'true');
        });
        modalIsOpen = false;
    }
}

// close modal with click or keypress on close button
closeBtn.forEach(function(closeBtn){
    closeBtn.addEventListener('click', function(e) { 
        closeModal();
    });
    closeBtn.addEventListener('keypress', function(e) {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            closeModal();
        }
    });
});

// close modal with escape key  
document.addEventListener('keyup', function(e) {
    if (e.keyCode === 27) {
        closeModal();
    }
});


// close modal from click on backdrop but not on the modal content
modalsBackdrop.forEach(function(modalsBackdrop){
    modalsBackdrop.addEventListener('click', function(e) {
        if (e.target.classList.contains('modal-backdrop')){
            closeModal();
        }
    });
});