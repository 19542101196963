let url = contextPath + "/.rest/delivery/infos-destinations/?lang=all";
let containerList = document.getElementsByClassName("list-destinations");
var result;
var arrayTitle = [];
var arrayItems = [];
var destinations;

if(containerList.length > 0){
  fetch(url)
  .then(response => response.json())
  .then(commits => result = commits.results)
  .then(()=>{
    //Sort by folder
    for (let i = 0; i < result.length; i++) {
      let title = result[i]["@path"].split("/");
      if (arrayTitle.length > 0) {
        for (let y = 0; y <= arrayTitle.length; y++) {
          if (y === arrayTitle.length) {
            arrayTitle.push(title[1]);
            arrayItems.push([result[i]]);
            break;
          }else{
            if (title[1] == arrayTitle[y]) {
              arrayItems[y].push(result[i]);
              break;
            }
          }         
        }
      }else{
        arrayTitle.push(title[1]);
        arrayItems.push([result[i]]);
      }
    }
  })
  .then(() => {
    //Add content to the containers
    for (let i = 0; i < containerList.length; i++) {
      let folder = containerList[i].getAttribute('data-folder');
      let content = "";
      let startOfTheUrl = (siteName == "bluecross-on" || lang == "en")? "travel-destinations/" : "destinations/";
      
      for (let y = 0; y < arrayTitle.length; y++) {
        if (arrayTitle[y] == folder) {
          content += "<ul>";
          for (let x = 0; x < arrayItems[y].length; x++) {
            let currentItem = arrayItems[y][x];
            content += "<li class='destination dest-"+folder+"'><a href='"+ startOfTheUrl + currentItem['@name'] + "' style='background-image: url("+ currentItem.banner['@link'] +")'><span>";
            content += (siteName == "bluecross-on" || lang == "en")? currentItem.countryName_en : currentItem.countryName;
            content += "</span></a></li>";
          }
          content += "</ul>";
          containerList[i].innerHTML = content;
          break;
        }
      }
    }
  })
  .then(()=>{
    let filters = document.getElementsByClassName("filter-destinations");
    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i];
      let folder = filters[i].getAttribute('data-folder');
      let destVar = "dest-" + folder;
      const relatedDeatinations = document.getElementsByClassName(destVar);
      
      filter.addEventListener("change", e => {
        let dest = relatedDeatinations;
        var value = e.target.value;
        for (let y = 0; y < dest.length; y++) {
          dest[y].classList.remove("hide");
          const title = dest[y].outerText,
            titleLowerCase = title.toLowerCase(),
            valueToLowerCase = value.toLowerCase();
          if(!titleLowerCase.includes(valueToLowerCase)){
            dest[y].classList.add("hide");
          }
        }
      })
    }
  });
}
