let acc = document.getElementsByClassName("cb-accordion-button");

for (let i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    let theTag = this.getAttribute('data-tag');
    for (y = 0; y < acc.length; y++) {
      let tag = acc[y].getAttribute('data-tag');
      if(theTag === tag){
        acc[y].classList.remove("active");
        acc[y].parentNode.classList.remove("active");
        let closepanel = acc[y].nextElementSibling;
        if (closepanel.style.maxHeight) {
          closepanel.style.maxHeight = null;
        }else{
          if(this === acc[y]){
            this.classList.add("active");
            this.parentNode.classList.add("active");
            let panel = this.nextElementSibling;
            panel.style.maxHeight = panel.scrollHeight + "px";
          }
        }
      }
    }
  });
}