window.addEventListener("DOMContentLoaded", (event) => {
    var iframeList = document.getElementsByClassName("iframe-component");
    var i;
    for (i = 0; i < iframeList.length; i++) {
        let iframeDiv = iframeList[i];
        if (iframeDiv.dataset.minin !== "undefined" &&  iframeDiv.dataset.minout !== "undefined" && iframeDiv.dataset.maxin !== "undefined" && iframeDiv.dataset.maxout !== "undefined"){
            var value = iframeDiv.offsetWidth,
                minIn = parseInt(iframeDiv.dataset.minin),
                minOut = parseInt(iframeDiv.dataset.minout),
                maxIn = parseInt(iframeDiv.dataset.maxin),
                maxOut = parseInt(iframeDiv.dataset.maxout),
                iframeHeight = (value - minIn) / (maxIn - minIn) * (maxOut - minOut) + minOut,
                iframeElement = iframeDiv.querySelector('iframe');

            iframeElement.setAttribute("style","height:"+iframeHeight+"px");
        }
    }
});