//import './sass/main'

import "./sass/main.scss";

//JS
import "./js/utils.js"
import "./js/svg.js"
import "./js/accordeon.js"
import "./js/carrousel.js"
import "./js/tabs.js"
import "./js/list-destinations.js"
import "./js/page-destinations.js"
import "./js/modal.js"
import "./js/menu.js"
import "./js/additionalmenu"
import "./js/iframe"
import "./js/iframe-unsubscribe"
import "./js/form"
import "./js/product-detail"
