let url = contextPath + "/.rest/delivery/infos-destinations/?lang=all",
  containerList = document.getElementsByClassName("destination-page"),
  breadcrumb = document.getElementsByClassName("bc-destination");
  

var result,
  pageDest,
  arrayTitle = [],
  arrayItems = [],
  destinations,
  referenceDestination;
if(containerList.length > 0){
  fetch(url)
  .then(response => response.json())
  .then(commits => result = commits.results)
  .then(()=>{
    //Sort by folder
    for (let i = 0; i < result.length; i++) {
    let title = result[i]["@name"];
      if (title == destinationId) {
        pageDest = result[i];
      }
      if (title == "canada"){
        referenceDestination = result[i];
      }
    }
  })
  .then(() => {
    //Add content to the containers
    if (pageDest && pageDest != "") {
      for (let i = 0; i < containerList.length; i++) {
        let flagDest = pageDest.flag['@link'],
          flagRef = referenceDestination.flag['@link'],
          flagAltDest = pageDest['@name'] + " flag",
          flagAltRef = referenceDestination['@name'] + " flag",
          continentDest = (siteName == "bluecross-on" || lang == "en")? pageDest.continent_en : pageDest.continent,
          continentRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.continent_en : referenceDestination.continent,
          popDest = (siteName == "bluecross-on" || lang == "en")? pageDest.population_en : pageDest.population,
          popRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.population_en : referenceDestination.population,
          areaDest = (siteName == "bluecross-on" || lang == "en")? pageDest.area_en : pageDest.area,
          areaRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.area_en : referenceDestination.area,
          capitalDest = (siteName == "bluecross-on" || lang == "en")? pageDest.capital_en : pageDest.capital,
          capitalRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.capital_en : referenceDestination.capital,
          currencyDest = (siteName == "bluecross-on" || lang == "en")? pageDest.currency_en : pageDest.currency,
          currencyRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.currency_en : referenceDestination.currency,
          langDest = (siteName == "bluecross-on" || lang == "en")? pageDest.officialLanguage_en : pageDest.officialLanguage,
          langRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.officialLanguage_en : referenceDestination.officialLanguage,
          driveDest = (siteName == "bluecross-on" || lang == "en")? pageDest.drive_en : pageDest.drive,
          driveRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.drive_en : referenceDestination.drive,
          dieselDest = (siteName == "bluecross-on" || lang == "en")? pageDest.dieselPrice_en : pageDest.dieselPrice,
          dieselRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.dieselPrice_en : referenceDestination.dieselPrice,
          gasDest = (siteName == "bluecross-on" || lang == "en")? pageDest.gasolinePrice_en : pageDest.gasolinePrice,
          gasRef = (siteName == "bluecross-on" || lang == "en")? referenceDestination.gasolinePrice_en : referenceDestination.gasolinePrice,
          banner = pageDest.banner['@link'],
          bannerAlt = (siteName == "bluecross-on" || lang == "en")? pageDest.bannerAlt_en : pageDest.bannerAlt;
          nameDest = (siteName == "bluecross-on" || lang == "en")? pageDest.countryName_en : pageDest.countryName,
          ctaLabel = (siteName == "bluecross-on" || lang == "en")? "Get a insurance quote for " : "Obtenir une soumission pour ",
          generalLabel = (siteName == "bluecross-on" || lang == "en")? "General" : "Général",
          continentLabel = (siteName == "bluecross-on" || lang == "en")? "Continent :" : "Continent :",
          popLabel = (siteName == "bluecross-on" || lang == "en")? "Population :" : "Population :",
          areaLabel = (siteName == "bluecross-on" || lang == "en")? "Area (km<sup>2</sup>) :" : "Superficie (km<sup>2</sup>) :",
          capitalLabel = (siteName == "bluecross-on" || lang == "en")? "Capital :" : "Capitale :",
          currencyLabel = (siteName == "bluecross-on" || lang == "en")? "Currency :" : "Monnaie :",
          langLabel = (siteName == "bluecross-on" || lang == "en")? "Official language(s) :" : "Langue(s) officielle(s) :",
          driveLabel = (siteName == "bluecross-on" || lang == "en")? "Drive :" : "Conduite :",
          dieselLabel = (siteName == "bluecross-on" || lang == "en")? "Diesel :" : "Diesel :",
          gasLabel = (siteName == "bluecross-on" || lang == "en")? "Gasoline :" : "Essence :",
          description = (siteName == "bluecross-on" || lang == "en")?pageDest.description_en : pageDest.description,
          nameRef = referenceDestination.countryName,
          shortName = pageDest.destinationShort;
          let startOfTheUrl;
            if(siteName == "bluecross-on"){
              startOfTheUrl = "/travel-insurance/travel-destinations"
            }else{
              startOfTheUrl = "/assurance-voyage/destinations"
            }
        let content = "<img src='" + banner + "' alt='" + bannerAlt + "'>";
        content += "<div class='title-flag-section'><img src='" + flagDest + "' alt='" + flagAltDest + "'><h2>" + nameDest + "</h2></div>";
        if(description != undefined){
          content += "<div class='destination-content'>" + description + "</div>";
        }
        content += "<div class='comp-canada-ctrl'><a id='comp-canada-bt' class='button _scriptcaptured hide' title='Afficher les données du Canada'><svg class='icon open' viewBox='0 0 100 100'><use href='#sprite-eye1' /></svg><svg class='icon close' viewBox='0 0 100 100'><use href='#sprite-eye2' /></svg><img class='flag-mini' src='" + flagRef + "' alt='" + flagAltRef + "'></a></div>";
        content += "<table class='desti-datas first'><thead><tr><th>" + generalLabel + "</th><td><span><img class='flag-mini' src='" + flagDest + "' alt='" + flagAltDest + "' style='display: inline;'></span></td><td class='comp-canada' style='display: table-cell;'><span><img class='flag-mini' src='" + flagRef + "' alt='" + flagAltRef + "' style='display: inline;'></span></td></tr></thead>";
        content += "<tbody><tr><td class='label dk-continent'>Continent:</td><td class='data'><a href='"+ startOfTheUrl +"'>" + continentDest + "</a></td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + continentRef + "</span></td></tr>";
        content += "<tr><td class='label dk-population'>" + popLabel + "</td><td class='data'>" + popDest + "</td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + popRef + "&nbsp;<small>(2012)</small></span></td></tr>"
        content += "<tr><td class='label dk-area'>" + areaLabel + "</td><td class='data'>" + areaDest + "</td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + areaRef + "</span></td></tr>";
        content += "<tr><td class='label dk-capital'>" + capitalLabel + "</td><td class='data'>" + capitalDest + "</td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + capitalRef + "</span></td></tr>";
        content += "<tr><td class='label dk-currency'>" + currencyLabel + "</td><td class='data'>" + currencyDest + "</td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + currencyRef + "</span></td></tr>";
        content += "<tr><td class='label dk-language'>" + langLabel + "</td><td class='data'>" + langDest + "</td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + langRef + "</span></td></tr>";
        content += "<tr><td class='label dk-drive'>" + driveLabel + "</td><td class='data'>" + driveDest + "</td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + driveRef + "</span></td></tr>";
        content += "<tr><td class='label dk-diesel'>" + dieselLabel + "</td><td class='data'>" + dieselDest + "</td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + dieselRef + "<small>(2012)</small></span></td></tr>";
        content += "<tr><td class='label dk-gasoline'>" + gasLabel + "</td><td class='data'>" + gasDest + "</td><td class='comp-canada' title='COM_DESTINATIONS_CA_COMPARE' style='display: table-cell;'><span>" + gasRef + "<small>(2013)</small></span></td></tr></tbody></table>";
        content += "<div class='cta-container p-4-y'><a href='" + travelInsuranceLink + "?destination=" + shortName + "' class='btn style-1'>" + ctaLabel + nameDest + "</a></div>";
        containerList[i].innerHTML = content;

        //Add item to the breadcrumb
        if(breadcrumb !== null){
          for (let x = 0; x < breadcrumb.length; x++) {
            const element = breadcrumb[x].innerHTML;
            const elemSplit = element.split("</ul>");
            const addToBread = "<li><a href='#'>" + nameDest + "</a></li></ul>"
            breadcrumb[x].innerHTML = elemSplit[0] + addToBread
          }
        }


        //add SEO

        let titleMT = (siteName == "bluecross-on" || lang == "en")? pageDest.titleMT_en : pageDest.titleMT,
        descriptionMT = (siteName == "bluecross-on" || lang == "en")? pageDest.descriptionMT_en : pageDest.descriptionMT,
        keywordMT = (siteName == "bluecross-on" || lang == "en")? pageDest.keywordMT_en : pageDest.keywordMT,
        titleOG = (siteName == "bluecross-on" || lang == "en")? pageDest.titleOG_en : pageDest.titleOG,
        imageOG = pageDest.imageOG,
        urlOG = (siteName == "bluecross-on" || lang == "en")? pageDest.urlOG_en : pageDest.urlOG,
        descriptionOG = (siteName == "bluecross-on" || lang == "en")? pageDest.descriptionOG_en : pageDest.descriptionOG,
        gaPageName = pageDest.gaPageName,
        gaContentGroup = pageDest.gaContentGroup,
        gaProductType = pageDest.gaProductType,
        canonicalLink = (siteName == "bluecross-on" || lang == "en")? pageDest.canonicalLink_en : pageDest.canonicalLink;
         
        let metas = document.getElementsByTagName('meta');
        if(titleMT && titleMT !==  ""){
          document.querySelector('title').textContent=titleMT;
        }
        if(descriptionMT && descriptionMT !==  ""){
          if(metas["description"]){
            metas["description"].content = descriptionMT;
          }
          else {
            let metaTag = document.createElement('meta')
            metaTag.name = "description";
            metaTag.content = descriptionMT;
            document.head.append(metaTag)
          }
        }
        if(keywordMT && keywordMT !==  ""){
          if(metas["keywords"]){
            metas["keywords"].content = keywordMT;
          }
          else {
            let metaTag = document.createElement('meta')
            metaTag.name = "keywords";
            metaTag.content = keywordMT;
            document.head.append(metaTag)
          }
        }

        if(titleOG && titleOG !==  ""){
          if(metas["og:title"]){
            metas["og:title"].content = titleOG;
          }
          else {
            let metaTag = document.createElement('meta')
            metaTag.name = "og:title";
            metaTag.content = titleOG;
            document.head.append(metaTag)
          }
        }

        if(imageOG && imageOG !==  ""){

          if(metas["og:image"]){
            metas["og:image"].content = imageOG["@link"];
          }
          else {
            let metaTag = document.createElement('meta')
            metaTag.name = "og:image";
            metaTag.content = imageOG["@link"];
            document.head.append(metaTag)
          }
        }

        if(urlOG && urlOG !==  ""){
          if(metas["og:url"]){
            metas["og:url"].content = urlOG;
          }
          else {
            let metaTag = document.createElement('meta')
            metaTag.name = "og:url";
            metaTag.content = urlOG;
            document.head.append(metaTag)
          }
        }

        if(descriptionOG && descriptionOG !==  ""){
          if(metas["og:description"]){
            metas["og:description"].content = descriptionOG;
          }
          else {
            let metaTag = document.createElement('meta')
            metaTag.name = "og:description";
            metaTag.content = descriptionOG;
            document.head.append(metaTag)
          }
        }

        //   TODO : check for Ga properties

        if(window.dataLayer && gaPageName && gaPageName !==  ""){
          window.dataLayer[0]["pageName"] = gaPageName;
        }

        if(window.dataLayer && gaContentGroup && gaContentGroup !==  ""){
          window.dataLayer[0]["contentGroup"] = gaContentGroup;
        }

        if(window.dataLayer && gaProductType && gaProductType !==  ""){
          window.dataLayer[0]["productType"] = gaProductType;
        }

        // Canonical link

        let canonicalTag = document.querySelector("link[rel='canonical']");

        if(canonicalLink && canonicalLink !==  ""){
          if(canonicalTag){
            canonicalTag.href = canonicalLink;
          }
          else {
            let newCanonicalTag = document.createElement('link')
            newCanonicalTag.rel = "canonical";
            newCanonicalTag.href = canonicalLink;
            document.head.append(newCanonicalTag)
          }
        }


      }
    }
    else {
      document.location.pathname = "/404";
    }
  })
  .then(()=>{
    //Show/Hide the reference country
    let buttonCompare = document.getElementById("comp-canada-bt"),
      tableContent = document.querySelector("table.desti-datas");
      if(buttonCompare){
        buttonCompare.addEventListener("click", ()=>{
          buttonCompare.classList.toggle("hide");
          buttonCompare.classList.toggle("show");
          tableContent.classList.toggle("show-ref");
        })
      }  
  });
}
