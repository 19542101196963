window.addEventListener("DOMContentLoaded", (event) => {
    var iframeList = document.getElementsByClassName("iframe-unsubscribe-component");
    var i;
    for (i = 0; i < iframeList.length; i++) {
        let iframeDiv = iframeList[i];
        if (iframeDiv.dataset.iframeurl !== "undefined"){
            var iframeUrl = iframeDiv.dataset.iframeurl,
                iframeId = iframeDiv.dataset.iframeid,
                iframeWidth = "100%",
                iframeHeight = 1,
                spMailingID = urlParam('spMailingID'),
                spUserID = urlParam('spUserID'),
                spJobID = urlParam('spJobID'),
                spReportId = urlParam('spReportId'),
                vs = urlParam('vs'),
                vsParam = "";

            if(vs!==null){
                vsParam = "&vs="+vs;
            }

            iframeUrl += "?spMailingID="+spMailingID+"&spUserID="+spUserID+"&spJobID="+spJobID+"&spReportId="+spReportId+vsParam;

            iframeDiv.innerHTML = '<iframe id="'+iframeId+'" src="'+iframeUrl+'" width="'+iframeWidth+'" height="'+iframeHeight+'" frameBorder="0" scrolling="no"></iframe>'

            // AutoResize Iframe Height
            // Listen for messages sent from the iFrame
            var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            var eventer = window[eventMethod];
            var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

            eventer(messageEvent,function(e) {
            // If the message is a resize frame request
                if (e.data.indexOf('resize::') != -1) {
                    var height = e.data.replace('resize::', '');
                    document.getElementById('wca-iframe').style.height = height+'px';
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
            } ,false);
        }
    }

    function urlParam(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results==null) {
            return null;
        }
        return decodeURI(results[1]) || 0;
    }
});